import { FC } from 'react';
import { Box, Card, CardHeader, Grid, Typography, useTheme } from '@mui/material';
import useOrganizationPricing from '@/hooks/useOrganizationPricing';

const OrganizationSubscriptionPreview: FC = () => {
    const { pricing } = useOrganizationPricing();
    const theme = useTheme();

    return (
        <Grid
            sx={{
                '& .MuiPaper-root': {
                    flex: 1,
                    padding: theme.spacing(1),
                },
            }}
            container
            spacing={3}
            justifyContent={'center'}
            marginTop={0.5}
        >
            {pricing.map((tier) => (
                <Grid xs={12} lg={4} item key={tier.title}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            // flexGrow: 1,
                            // display: 'flex',
                        }}
                        elevation={5}
                    >
                        <CardHeader
                            sx={{
                                // flexGrow: 1,
                                // display: 'flex',
                                // flexDirection: 'column',
                                // justifyContent: 'flex-start',
                                borderRadius: 2,
                                backgroundColor: (theme) => theme.palette.secondary.main,
                            }}
                            title={
                                <Typography variant={'h4'} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {tier.title.toUpperCase()}
                                </Typography>
                            }
                        />
                        <Box
                            sx={{
                                mb: 2,
                                mt: 4,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}
                        >
                            {tier.maxQuantity === 1 ? (
                                <>
                                    <Typography variant={'h3'}>${tier.priceMonthly}</Typography>
                                    <Typography variant={'h6'}>/ MO</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant={'h3'}>${tier.priceMonthly}</Typography>
                                    <Typography sx={{ ml: 1 }} variant={'h6'}>
                                        / MO / SEAT
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                justifyItems: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <ul
                                style={{
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.grey[100]
                                            : theme.palette.text.primary,
                                }}
                            >
                                {tier.details.map((detail, i) => (
                                    <li
                                        key={i}
                                        style={{
                                            listStyle:
                                                i === 0 && tier.stripePriceMonthly !== 'solo-monthly'
                                                    ? 'none'
                                                    : undefined,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize:
                                                    i === 0 && tier.stripePriceMonthly !== 'solo-monthly' ? 21.5 : 19.5,
                                                fontWeight:
                                                    i === 0 && tier.stripePriceMonthly !== 'solo-monthly'
                                                        ? 'bold'
                                                        : undefined,
                                            }}
                                        >
                                            {detail}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default OrganizationSubscriptionPreview;
