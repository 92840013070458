import apiSlice from '../apiSlice';

const OrganizationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizations: builder.mutation<GetOrganizationsResponse, GetOrganizationsRequest>({
            query: ({ options }) => ({
                url: `developer/organizations${options}`,
                method: 'GET',
            }),
        }),
        getAllOrganizations: builder.query<Organization[], null>({
            query: () => ({
                url: `developer/organizations?limit=-1&sort=created`,
                method: 'GET',
            }),
            providesTags: ['user-organizations'],
        }),
        getOrganization: builder.mutation<Organization, GetOrganizationRequest>({
            query: ({ orgId }) => ({
                url: `developer/organizations/${orgId}?load=currentSubscription,memberAppend`,
            }),
        }),
        getOrganizationProjects: builder.mutation<GetOrganizationProjectsResponse, GetOrganizationProjectsRequest>({
            query: ({ options, orgId }) => ({
                url: `developer/organizations/${orgId}/projects${options}`,
                method: 'GET',
            }),
        }),
        getAllOrganizationProjects: builder.mutation<Project[], GetOrganizationProjectsRequest>({
            query: ({ orgId }) => ({
                url: `developer/organizations/${orgId}/projects?limit=-1&sort=created`,
                method: 'GET',
            }),
        }),
        // getAllOrganizationProjects: builder.query<Project[], GetOrganizationProjectsRequest>({
        //     query: ({ orgId }) => ({
        //         url: `developer/organizations/${orgId}/projects?limit=-1`,
        //         method: 'GET',
        //     }),
        // }),
        storeOrganization: builder.mutation<Organization, StoreOrganizationRequest>({
            query: ({ name }) => ({
                url: `developer/organizations`,
                method: 'POST',
                body: { name },
            }),
            invalidatesTags: ['user-organizations'],
        }),
        updateOrganization: builder.mutation<Organization, UpdateOrganizationRequest>({
            query: ({ orgId, name, joinable, require_2fa }) => ({
                url: `developer/organizations/${orgId}`,
                method: 'PUT',
                body: { name, joinable, require_2fa },
            }),
            invalidatesTags: ['user-organizations'],
        }),
        archiveOrganization: builder.mutation<null, ArchiveOrganizationRequest>({
            query: ({ orgId }) => ({
                url: `developer/organizations/${orgId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['user-organizations'],
        }),
        restoreOrganization: builder.mutation<Organization, RestoreOrganizationRequest>({
            query: ({ orgId }) => ({
                url: `developer/organizations/${orgId}/restore`,
                method: 'PUT',
            }),
            invalidatesTags: ['user-organizations'],
        }),
        updateOrganizationAvatar: builder.mutation<UpdateOrganizationAvatarResponse, UpdateOrganizationAvatarRequest>({
            query: ({ orgId, avatar }) => ({
                url: `developer/organizations/${orgId}/avatar`,
                method: 'POST',
                body: avatar,
            }),
            invalidatesTags: ['user-organizations'],
        }),
        getSharedOrganization: builder.query<Organization, GetSharedOrganizationRequest>({
            query: ({ code }) => ({
                url: `shared/organizations/${code}`,
            }),
        }),
    }),
});

export default OrganizationApi;
export const {
    useGetOrganizationsMutation,
    useGetAllOrganizationsQuery,
    useGetOrganizationMutation,
    useGetOrganizationProjectsMutation,
    useGetAllOrganizationProjectsMutation,
    useStoreOrganizationMutation,
    useUpdateOrganizationMutation,
    useArchiveOrganizationMutation,
    useUpdateOrganizationAvatarMutation,
    useRestoreOrganizationMutation,
    useGetSharedOrganizationQuery,
} = OrganizationApi;
