import { Link as RouterLink } from 'react-router-dom';
import {
    Badge,
    Divider,
    IconButton,
    InputAdornment,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Skeleton,
    TextField,
    Typography,
} from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { useWorkspace } from '@/contexts/WorkspaceContext';
import OrganizationStorageBar from '@/Organizations/OrganizationStorageBar';
import {
    AccountBalance,
    AutoDelete,
    Close,
    DeviceHub,
    FolderCopy,
    Group,
    Home,
    Notifications,
    PermMedia,
    ViewInAr,
} from '@mui/icons-material';
import ProjectSidebar from '@/Layout/Sidebar/ProjectSidebar';
import { ChangeEventHandler, FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import AddProject from '@/Projects/AddProject';

type OrganizationSidebarProps = {
    toggleDrawer?: () => void;
};

const OrganizationSidebar: FC<OrganizationSidebarProps> = ({ toggleDrawer }) => {
    const { organization, projects, refetchProjects, project, workspaceProjectId, isOrganizationMemberNeeding2FA } =
        useWorkspace();
    const [expandedId, setExpandedId] = useState<string | undefined>(undefined);
    const [projectNameSearch, setProjectNameSearch] = useState<string>('');
    const elmId = useMemo(() => Date.now(), []);
    const { classes } = useStyles();
    const location = useLocation();
    const orgPath = `/organizations/${organization?.id}`;
    const soloProjectPath = `/organizations/${organization?.id}/projects/${project?.id}`;

    const toggleExpanded = (id: string) => {
        if (id === project?.id) {
            return;
        }

        setExpandedId((current) => (current === id ? undefined : id));
    };

    const handleProjectSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProjectNameSearch(e.target.value);
    };

    const handleClearProjectSearch = () => {
        setProjectNameSearch('');
    };

    useEffect(() => {
        setExpandedId(undefined);
    }, [location.pathname]);

    useEffect(() => {
        setProjectNameSearch('');
    }, [organization?.id]);

    return (
        <>
            {!organization ? (
                <>
                    <MenuList sx={{ paddingY: 0 }}>
                        <MenuItem sx={{ p: 0, mb: 0 }} disabled={true}>
                            <Skeleton
                                sx={{ borderTopRightRadius: (theme) => theme.spacing(1) }}
                                style={{ flexGrow: 1 }}
                                animation={'wave'}
                                variant={'rectangular'}
                                height={45}
                            />
                        </MenuItem>
                        {[...Array(4)].map((_el, i) => (
                            <MenuItem sx={{ px: 0, pb: 0.1 }} key={i} disabled={true}>
                                <Skeleton
                                    style={{ flexGrow: 1 }}
                                    animation={'wave'}
                                    variant={'rectangular'}
                                    height={35}
                                />
                            </MenuItem>
                        ))}
                    </MenuList>
                    <Divider sx={{ mt: 1 }} variant={'middle'} />
                    <div className={classes.storage}>
                        <Skeleton style={{ flexGrow: 1 }} animation={'wave'} variant={'rectangular'} height={85} />
                    </div>
                </>
            ) : (
                <>
                    <MenuList className={classes.menuList}>
                        <MenuItem className={classes.topItem} selected={location.pathname === orgPath}>
                            <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={orgPath}>
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={'subtitle1'} noWrap={true}>
                                        {organization.name}
                                    </Typography>
                                </ListItemText>
                            </Link>
                        </MenuItem>
                        {organization.member?.organizationGates.owner && (
                            <MenuItem selected={location.pathname === `${orgPath}/billing`}>
                                <Link
                                    onClick={toggleDrawer}
                                    className={classes.link}
                                    component={RouterLink}
                                    to={`${orgPath}/billing`}
                                >
                                    <ListItemIcon>
                                        <AccountBalance />
                                    </ListItemIcon>
                                    <Badge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        invisible={!organization.is_locked}
                                        color={'info'}
                                        variant={'dot'}
                                    >
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Billing</Typography>
                                        </ListItemText>
                                    </Badge>
                                </Link>
                            </MenuItem>
                        )}
                        {organization.tier !== 'pending' &&
                            organization.member &&
                            organization.member?.organizationGates.viewer && (
                                <MenuItem selected={location.pathname === `${orgPath}/notifications`}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${orgPath}/notifications`}
                                    >
                                        <ListItemIcon>
                                            <Badge
                                                color={'secondary'}
                                                badgeContent={organization.member.unreadNotificationsCount}
                                                max={99}
                                            >
                                                <Notifications />
                                            </Badge>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Notifications</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                            )}
                        {organization.supports_projects &&
                            ((organization.supports_teams && organization.member?.organizationGates.admin) ||
                                (!organization.supports_teams && organization.member?.organizationGates.viewer)) && (
                                <MenuItem selected={location.pathname === `${orgPath}/members`}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${orgPath}/members`}
                                    >
                                        <ListItemIcon>
                                            <Group />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Members</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                            )}
                        {organization.tier !== 'pending' && organization.member?.organizationGates.admin && (
                            <MenuItem selected={location.pathname === `${orgPath}/integrations`}>
                                <Link
                                    onClick={toggleDrawer}
                                    className={classes.link}
                                    component={RouterLink}
                                    to={`${orgPath}/integrations`}
                                >
                                    <ListItemIcon>
                                        <DeviceHub />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant={'subtitle1'}>Integrations</Typography>
                                    </ListItemText>
                                </Link>
                            </MenuItem>
                        )}
                        {organization.tier === 'solo' && organization.member?.organizationGates.viewer && (
                            <>
                                <MenuItem selected={location.pathname.includes(`${soloProjectPath}/models`)}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${soloProjectPath}/models`}
                                    >
                                        <ListItemIcon>
                                            <ViewInAr />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Models</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                                <MenuItem selected={location.pathname.includes(`${soloProjectPath}/folders`)}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${soloProjectPath}/folders`}
                                    >
                                        <ListItemIcon>
                                            <FolderCopy />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Folders</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                                <MenuItem selected={location.pathname.includes(`${soloProjectPath}/media`)}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${soloProjectPath}/media`}
                                    >
                                        <ListItemIcon>
                                            <PermMedia />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Media</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                                <MenuItem selected={location.pathname.includes(`${soloProjectPath}/trash`)}>
                                    <Link
                                        onClick={toggleDrawer}
                                        className={classes.link}
                                        component={RouterLink}
                                        to={`${soloProjectPath}/trash`}
                                    >
                                        <ListItemIcon>
                                            <AutoDelete />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Trash</Typography>
                                        </ListItemText>
                                    </Link>
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                    <Divider variant={'middle'} />
                    {organization.member?.organizationGates.viewer && (
                        <div className={classes.storage}>
                            <OrganizationStorageBar {...{ organization, large: true }} />
                        </div>
                    )}
                </>
            )}
            {organization && organization.supports_projects && !isOrganizationMemberNeeding2FA && (
                <>
                    <Divider variant={'middle'} sx={{ mb: 1 }}>
                        Projects
                    </Divider>
                    {projects && projects.length > 0 && (
                        <TextField
                            InputProps={{
                                endAdornment:
                                    projectNameSearch.length > 0 ? (
                                        <InputAdornment position={'end'}>
                                            <IconButton
                                                size={'small'}
                                                aria-label={'Clear'}
                                                onClick={handleClearProjectSearch}
                                                onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                                                edge={'end'}
                                            >
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined,
                            }}
                            id={`project-name-search-${elmId}`}
                            label={'Search projects'}
                            value={projectNameSearch}
                            variant={'outlined'}
                            onChange={handleProjectSearchChange}
                            size={'small'}
                            sx={{
                                marginRight: 2,
                                marginLeft: 2,
                                marginBottom: 1.5,
                            }}
                        />
                    )}
                    <MenuList className={classes.projectMenuList}>
                        {projects
                            ? (projectNameSearch.length
                                  ? projects.filter((project) => project.name.toLowerCase().includes(projectNameSearch))
                                  : projects
                              ).map((currentProject) => (
                                  <ProjectSidebar
                                      key={currentProject.id}
                                      {...{
                                          project: currentProject,
                                          member: organization?.member,
                                          forceOpen: currentProject.id === workspaceProjectId,
                                          toggleDrawer,
                                          expandedId,
                                          toggleExpanded,
                                      }}
                                  />
                              ))
                            : [...Array(3)].map((el, i) => (
                                  <MenuItem sx={{ px: 0 }} key={i} disabled={true}>
                                      <Skeleton
                                          style={{ flexGrow: 1 }}
                                          animation={'wave'}
                                          variant={'rectangular'}
                                          height={30}
                                      />
                                  </MenuItem>
                              ))}
                        {organization && organization.member && organization.member.organizationGates.editor && (
                            <div className={classes.addProject}>
                                {projects && projects.length > 0 && <Divider variant={'middle'} sx={{ mb: 1 }} />}
                                <AddProject {...{ organization, fullSmallButton: true, refetchProjects }} />
                            </div>
                        )}
                    </MenuList>
                </>
            )}
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    storage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    menuList: {
        paddingTop: 0,
        '& .MuiMenuItem-root': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    projectMenuList: {
        paddingTop: 0,
        paddingBottom: 0,
        overflowY: 'auto',
        transition: `max-height 300ms ease-in-out`,
        '&::-webkit-scrollbar': {
            width: 8,
        },
        scrollbarWidth: 'thin',
        minHeight: 250,
    },
    topItem: {
        borderTopRightRadius: theme.spacing(1),
    },
    addProject: {
        marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(0.5),
    },
}));

export default OrganizationSidebar;
