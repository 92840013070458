type OrganizationPricingTier = {
    title: string;
    maxQuantity: number;
    priceMonthly: number;
    stripePriceMonthly: OrganizationPrice;
    details: string[];
    disabled: boolean;
};

type OrganizationPricing = {
    pricing: OrganizationPricingTier[];
};

const useOrganizationPricing = (): OrganizationPricing => {
    const pricing: OrganizationPricingTier[] = [
        {
            disabled: false,
            title: 'Solo',
            maxQuantity: 1,
            priceMonthly: 7.0,
            stripePriceMonthly: 'solo-monthly',
            details: [
                '50 GB of storage',
                'Unlimited sharing',
                'Model commenting',
                'ProRes video exporting',
                'Blender plugin access',
                'Discord & Slack webhooks',
            ],
        },
        {
            disabled: false,
            title: 'Team',
            maxQuantity: 40,
            priceMonthly: 20.0,
            stripePriceMonthly: 'team-monthly',
            details: [
                'Everything in Solo plus:',
                '250 GB of storage',
                'Organization-specific permissions',
                'Unlimited projects',
                'Unlimited viewers & commenters',
                'Model approvals',
                'Access to beta features',
            ],
        },
        {
            disabled: false,
            title: 'Enterprise',
            maxQuantity: 100,
            priceMonthly: 75.0,
            stripePriceMonthly: 'enterprise-monthly',
            details: [
                'Everything in Team plus:',
                '1 TB of storage',
                'Unlimited contributor access',
                'Project-specific permissions',
                'Cross-organization migration',
                'Model lifecycle management',
                'Two-Factor Authentication',
                'Priority support',
            ],
        },
    ];

    return { pricing };
};

export default useOrganizationPricing;
